import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import Table from '../components/Table'
import ProcedureListing from '../components/ProcedureListing'

export default function PackagingProcedureTemplate({ data, pageContext }) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const documentName = filePathParts[2]
  return (
    <Layout title={documentName} filePath={filePath} tableOfContents="true">
      <h1 className="title is-1">Overview</h1>
      <p>
        This procedure is an incoming inspection document used to determine the
        acceptability of the following part(s):
      </p>
      <Table table={frontmatter.partAcceptabilityTable} />
      <ProcedureListing procedure={frontmatter.packagingProcedure} />
      <ProcedureListing procedure={frontmatter.boxingProcedure} />
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQueryPackagingProcedure($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        templateName
        partAcceptabilityTable {
          partNumber
          description
          image
        }
        packagingProcedure {
          procedureDescription
          steps {
            stepDescription
            images {
              imagePath
              caption
            }
          }
        }
        boxingProcedure {
          procedureDescription
          steps {
            stepDescription
            images {
              imagePath
              caption
            }
          }
        }
      }
    }
  }
`
